import { gql } from '@apollo/client';

export const PROJECTS_BY_FILTERS = gql`
    query ProjectByFilters ($data: projectsByFiltersInput){
      projectsByFilters(data: $data) {
        main_app_user_id
        name
        id
        created_at
        updated_at
        owner_id
        project_schema_id
        title
        progress
        fields
      }
    }
`;



export const PROJECTS_BY_USERS = gql`
    query Projects ($galleryType: GalleryType!, $users: [Int], $AlpType: [Int]){
        projects(galleryType: $galleryType, users: $users, ALPType: $AlpType) {
          id
          owner {
            mainAppUserId
          }
          title
          updatedAt
          progress
          projectSchemaId
          projectCoauthors {
            id
            author {
              id
              name
            }
          }
        }
    }`;
