import React from "react";
import { Col, Row, Table } from 'antd';
import moment from "moment";
import Dot from "./Dot";
import { AlpProgress } from "../../../../functions/AlpProgress";

const AlpProjects = (props) => {
    const { alp: { token } } = JSON.parse(localStorage.getItem("nisl-data"));
    const { REACT_APP_PORTAL_URL } = process.env
    const columns = [
        {
            title: "",
            dataIndex: "progress",
            width: "5%",
            render: (text) => {
                let { color } = AlpProgress(text);
                return <Dot dotColor={color} showModal={false}></Dot>
            }
        },
        {
            title: "Name",
            dataIndex: "title",
            width: props?.showCoauthors ? "35%" : "70%",
            render: (text, record) => {
                return (
                    <a style={{ color: 'black' }} href={`${REACT_APP_PORTAL_URL}?view=external_ncee&back_to=${window.location.href}&action=template_old&can_comment=false&id_project=${record.id}&alp_token=${token}`}>
                        {text}
                    </a>
                )
            }
        },
        {
            title: "Date Modified",
            dataIndex: "updatedAt",
            width: props?.showCoauthors ? "15%" : "30%",
            render: (text, record) => {
                return (
                    <span>
                        {moment(text).format("M/D/YY")}
                    </span>
                )
            }
        },
        {  ...(props?.showCoauthors ?
            {
                title: "Co-Authors",
                dataIndex: "projectCoauthors",
                width: "35%",
                render: (text) => {
                    return text.flatMap(coauthor => coauthor.author.name).join(', ')
                }
            }
            : null
        )}
    ]
    return <>
        <Table className={'alp-projects-table'} size="small" columns={columns} dataSource={props.projects} pagination={false}></Table>
        <Row style={{ paddingTop: '10px', lineHeight: '1.5' }}>
            <Col span={15} offset={9}>
                {`Click a ${props.typeSchema} to view`}
            </Col>
        </Row>
    </>

}

export default AlpProjects;
