import React, { useState, useEffect } from 'react';
import { createUseStyles } from "react-jss";
import jwt from "jsonwebtoken";
import {
    Row,
    Col,
    Layout,
    Button,
    Space,
    Modal,
    Spin,
    message
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import AttendanceReport from "./AttendanceReport";
import AttendanceAudit from "./AttendanceAudit";
import API from "../../../../../api";
import moment from "moment";
import { NISL_FLORIDA_2022, NISL_PIL_2023, NISL_NATIONAL_2021 } from '../../../../../config/courses';

const { Content } = Layout;
const useStyles = createUseStyles({
    container: {
        '& Button': {
            width: '90%',
            height: '40px',
            marginTop: '0.2em',
        },
    },
    bodyStyle: {
        '& .ant-modal-body': {
            height: 'calc(70vh - 20px) !important',
            overflowY: 'scroll',
            paddingTop: '0px !important'
        },
    },
    black:{
        color: 'black',
    },
    flex: {
        display: 'flex',
    },
    biggerSpin: {
        maxHeight:'70vh !important',
        height: '70vh !important',
    },
});

const RepostsAndDownloads = (props) => {
    const [loading, setLoading] = useState(false);
    const [attendanceReportModalVisibility, setAttendanceReportModalVisibility] = useState(false);
    const [attendanceAuditModalVisibility, setAttendanceAuditModalVisibility] = useState(false);
    const [showContextAnalysis, setShowContextAnalysis] = useState(false);
    const [token, setToken] = useState(null);
    const {roles : user_roles} = JSON.parse(localStorage.getItem("user-data"))
    const [showAttendanceAudit, setShowAttendanceAudit] = useState();
    const [exportingAtendanceXls, setExportingAtendanceXls] = useState(false);

    const {REACT_APP_MODERN_SIGNAL_URL, REACT_APP_CMS_URL, REACT_APP_ENV } = process.env;

    const attendanceReportModalHandler = () => {
        setAttendanceReportModalVisibility(!attendanceReportModalVisibility);
    }

    const attendanceAuditModalHandler = () => {
        if (props.dataTable.length > 0) {
            setAttendanceAuditModalVisibility(!attendanceAuditModalVisibility);
        }else{
            Modal.info({
                content: <div className={classes.flex}>Attendance has been saved. You may make any changes and re-save if required. </div>,
                icon: <CheckCircleOutlined className={classes.successIcon} />,
                okText: "OK"
            });

        }
    }
    const exportRoster = () => {
        setLoading(true);
        API.get(`api/rosterReport/${props.cohortId}/${props.eventInfo.eventId}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            setLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.href = url;
            link.setAttribute("download", `Roster for ${props.cohortInfo.cohortName} ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }
    const exportSingIn = () => {
        setLoading(true);
        API.get(`api/sing_in_file/${props.cohortId}/${props.eventInfo.eventId}/${props.sortDesc}/false`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            setLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Sign-In Sheet for ${props.cohortInfo.cohortName} - First Names.pdf`);
            document.body.appendChild(link);
            link.click();
        })
        API.get(`api/sing_in_file/${props.cohortId}/${props.eventInfo.eventId}/${props.sortDesc}/true`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            setLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Sign-In Sheet for ${props.cohortInfo.cohortName} - Last Names.pdf`);
            document.body.appendChild(link);
            link.click();
        })
    }
    const getAttendanceReport = () => {
        setExportingAtendanceXls(true);
        API.get(`api/export_attendance_report/${props.cohortId}`, {
            responseType: "blob",
            headers: { "Content-Type": "application/vnd.ms-excel" }
        }).then(response => {
            setExportingAtendanceXls(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
            link.setAttribute("download", `Attendance Report for ${props.cohortInfo.cohortName} ${formatDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }
    const downloadAllProjects = () => {

        if(props.totalWithProgress >= 1){
            setLoading(true);
            API.get(`api/get_projects_by_cohort/${props.cohortId}`, {
                responseType: "blob",
                headers: { "Content-Type": "application/zip" }
            }).then(response => {
                setLoading(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const now = moment().format('Y-M-D h-mm a');
                link.setAttribute("download", `AL Projects for Cohort ${props.cohortInfo.cohortName} - ${now}.zip`);
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                setLoading(false);
                message.error("None of the participants have progress on their projects.")
            });
        }else{
            message.error("None of the participants have progress on their projects.")
        }
    }
    const findContextAnalysisButton = () => {
        let courseID = parseInt(props.cohortInfo.courseId);
        if ((courseID === NISL_NATIONAL_2021 || courseID === NISL_FLORIDA_2022 || courseID === NISL_PIL_2023) && moment(props.cohortInfo.courseStartDate).isAfter('2021-09-15')) {
            setShowContextAnalysis(true);
            createJwt();
        }
    }
    const createJwt = async () => {
        setLoading(true);
        const data = {
            ToolID: REACT_APP_ENV === "production" ? "27" : "23", //STAGE or PREP = 23 // PROD = 27
            CohortID: props.cohort
        }
        const urlSsoToken = `${REACT_APP_CMS_URL}/api/generateTokenContextAnalisys.php?cohort_id=${String(data.CohortID)}&tool_id=${String(data.ToolID)}`;

        fetch(urlSsoToken)
            .then(response => response.json())
            .then(data => {
                setToken(data);
                setLoading(false);
            })
            .catch((error) => console.log(error));


        const customerPrivKey = "secret";
        setToken(jwt.sign(data, customerPrivKey));
    }

    const classes = useStyles();
    useEffect(() => {
        findContextAnalysisButton();
        if(
            user_roles.find(v => v.slug === "facilitator_national")
            || user_roles.find(v => v.slug === "facilitator_local")
            || user_roles.find(v => v.slug === "facilitator_local_inc")
            || user_roles.find(v => v.slug === "administrator")
        ){
            setShowAttendanceAudit(true);
        }else{
            setShowAttendanceAudit(false);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <Content className={classes.container}>
            <Spin spinning={loading}>
                <Space direction="vertical" size={20}>
                    <Row justify="center">
                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                            <Button onClick={attendanceReportModalHandler} type="primary" className={props.buttonClass}>
                                Attendance <br />
                                Report
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            This new Attendance Report shows all cohort participants and their course attendance through all of the units,
                            including make-up attendance. The data for the cohort can be downloaded in XLS format.
                        </Col>
                    </Row>
                    {showAttendanceAudit && <Row>
                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                            <Button onClick={attendanceAuditModalHandler} type="primary" className={props.buttonClass}>
                                <Space direction="vertical" size={1}>
                                    <div>Attendance</div>
                                    <div>Audit</div>
                                </Space>
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            Detailed list of attendance and each participant's absences, and eligibility to graduate from the program.
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                            <Button onClick={exportSingIn} type="primary" className={props.buttonClass}>
                                <Space direction="vertical" size={1}>
                                    <div>Sign-In Sheet</div>
                                </Space>
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            You may download a pre-formatted PDF that can be used as a sign-in sheet.
                            Two files will download, one with the participants' First Names in the first column on the left.
                            The other file will include the participants' Last Names in the first column on the left.
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                            <Button onClick={exportRoster} type="primary" className={props.buttonClass}>
                                <Space direction="vertical" size={1}>
                                    <div>Export</div>
                                    <div>Roster</div>
                                </Space>
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            You may download the roster data for this one unit (in XLS format), to use as a basis for a sign-in sheet etc.
                            This can be helpful if the district prefers to include additional columns on the sign-in sheet beyond those that we provide in the PDF sign-in sheet that's available above.
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                            <Button onClick={downloadAllProjects} type="primary" className={props.buttonClass} >
                                <Space direction="vertical" size={1}>
                                    <div>All</div>
                                    <div>Projects</div>
                                </Space>
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                            Download a ZIP file that includes the Action Learning project for each participant in this cohort.
                            The ZIP file also includes a PDF that contains all the Action Learning projects together, sorted alphabetically.
                        </Col>
                    </Row>
                    {showContextAnalysis &&
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4} >
                                <Button onClick={() => window.open(`${REACT_APP_MODERN_SIGNAL_URL}/Facilitator/CAReport?token=${token}`, "_blank")} type="primary" className={props.buttonClass}>
                                    <Space direction="vertical" size={1}>
                                        <div>Context Analysis</div>
                                        <div>Tool Report</div>
                                    </Space>
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={20} >
                                Display or download a report that shows on one report - for this cohort - each participants' responses to the Context Analysis Tool.
                            </Col>
                        </Row>
                    }
                </Space>
                <Modal
                    width='95%'
                    key="attendanceReport"
                    title='Attendance Report'
                    visible={attendanceReportModalVisibility}
                    onCancel={()=>{attendanceReportModalHandler();props.reportsAndDownloadsModalHandler();}}
                    onOk={getAttendanceReport}
                    okText='Export xlsx'
                    className={classes.bodyStyle}
                    destroyOnClose={true}
                >
                    <Spin className={classes.biggerSpin} spinning={exportingAtendanceXls}>
                        <AttendanceReport
                            cohortId={props.cohortId}
                            reportsAndDownloadsModalHandler={props.reportsAndDownloadsModalHandler}
                            cohortInfo={props.cohortInfo}
                        />
                    </Spin>
                </Modal>
                <Modal
                    width='80%'
                    key="attendanceAudit"
                    title='Attendance Audit'
                    visible={attendanceAuditModalVisibility}
                    onCancel={()=>{attendanceAuditModalHandler();props.reportsAndDownloadsModalHandler();}}
                    footer={null}
                    className={classes.bodyStyle}
                >
                    <AttendanceAudit
                        cohortId={props.cohortId}
                        cohortInfo={props.cohortInfo}
                        buttonClass={props.buttonClass}
                        dataTable={props.dataTable}
                    />
                </Modal>
            </Spin>
        </Content>
    )
}

export default RepostsAndDownloads;
