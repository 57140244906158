import React from "react";
import Icon from "@ant-design/icons";

const UserCheckSvg = () => (
    <svg version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="40px"
        viewBox="0 0 405.939 405.939"
     >
   <path d="M249.607,198.465H129.268C63.145,198.465,0,254.578,0,320.79v10.017c0,16.51,12.54,30.042,29.05,30.042h210.909
       c-3.516-9.833-5.413-20.475-5.413-31.572c0-52.23,42.486-94.724,94.724-94.724c3.428,0,6.767,0.184,10.017,0.448
       C315.188,212.622,282.806,198.465,249.607,198.465z M189.435,0c-49.796,0-90.208,40.412-90.208,90.208s40.412,90.209,90.208,90.209
       c49.797,0,90.209-40.413,90.209-90.209S239.231,0,189.435,0z M329.263,252.586c-42.35,0-76.677,34.326-76.677,76.677
       s34.333,76.677,76.677,76.677s76.677-34.326,76.677-76.67C405.939,286.919,371.613,252.586,329.263,252.586z M372.688,314.323
       l-49.164,49.164c-3.509,3.509-9.248,3.509-12.757,0l-24.745-24.745c-3.509-3.509-3.509-9.248,0-12.757l6.379-6.379
       c3.509-3.509,9.248-3.509,12.757,0l11.981,11.981l36.407-36.407c3.509-3.509,9.248-3.509,12.757,0l6.378,6.379
       C376.196,305.068,376.196,310.814,372.688,314.323z"/>

</svg>
);

export const UserCheck = (props) => <Icon component={UserCheckSvg} {...props} />;
