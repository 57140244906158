/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "antd";
import { createPhoneMask } from "../../functions/createPhoneMask";
import defaultImage from "../../assets/img/faces/face-0.jpg";
import { EditOutlined } from '@ant-design/icons';

const Header = ({ user, handleChangeToProfile, fromUser = false }) => {

    const [imageUrlWithValidate, setimageUrlWithValidate] = useState(defaultImage);
    useEffect(() => {

        if (user.avatar != null && user.avatar !== undefined) {

            var request = new XMLHttpRequest();
            request.open("GET", user.avatar, true);
            request.send();
            request.onload = function () {

                if (request.status === 200 && !request.responseURL.includes('json')) {
                    setimageUrlWithValidate(user.avatar);
                } else {
                    setimageUrlWithValidate(defaultImage);
                }
            }
        } else {
            setimageUrlWithValidate(defaultImage);
        }
        //eslint-disable-next-line
    }, [user?.avatar]);


    return (
        <>
            <Row className='user-header' style={{ height: "100%", padding: "0px", fontSize: '13px' }} align="middle" gutter={[0, 0]}>
                <Col md={4} lg={4} className='profile-photo'>
                    <div className="pages-profile-upload-avatar" style={{ marginLeft: "75px", width: "90px", height: "90px" }}>
                        <img
                            src={imageUrlWithValidate}
                            className="pages-profile-upload-avatar-img"
                            alt="user-profile"
                        />
                    </div>
                </Col>
                <Col md={14} xl={14} className="column-profile">
                    {JSON.parse(localStorage.getItem('user-data')).id === user?.id ?
                        <Row gutter={[0, 5]} className='edit-profile-button'>
                            <Col xl={8} onClick={handleChangeToProfile}>
                                <a href="javascript::void(0)" el="noopener noreferrer" style={{ fontWeight: 'bold', color: '#343773' }}>
                                    Profile
                                    <EditOutlined style={{ fontSize: '20px' }} />
                                </a>
                            </Col>
                        </Row>
                        : ""}
                    <Row gutter={[0, 5]}>
                        <Col span={3}>
                            <span >
                                Phone
                            </span>
                        </Col>
                        <Col md={8} xl={8}>
                            <span>
                                {createPhoneMask(user?.phone)}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[0, 5]}>
                        <Col span={3}>
                            <span >
                                Email
                            </span>
                        </Col>
                        <Col md={12} xl={12}>
                            <span >
                                {user?.email}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[0, 5]}>
                        <Col span={3}>
                            <span >
                                Job Title
                            </span>
                        </Col>
                        <Col md={12} xl={12}>
                            <span >
                                {user?.job_title}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[0, 5]}>
                        <Col span={3}>
                            <span >
                                School
                            </span>
                        </Col>
                        <Col md={12} xl={12}>
                            <span >
                                {user?.school_data?.school_name}
                            </span>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} xl={6} style={{ color: "#313777", textAlign: "center", fontSize: "13px" }}>
                    {(!fromUser) ?
                        <div style={{ width: "105%", textAlign: "center", border: "1px solid #5755a2", float: "right" }}>
                            We display attendance records to participants in EDP, NISL and PIL courses.
                        </div>
                        : ""}
                </Col>
            </Row>
        </>
    );
};

Header.propTypes = {
    user: PropTypes.object,
    handleChangeToProfile: PropTypes.func
};

export default Header;
