import React, { useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    Radio,
    Spin,
    Button,
    Select
} from "antd";

import moment from "moment";
import { createUseStyles } from "react-jss";
import API from "../../../../api";
const { Option } = Select;
const { REACT_APP_URL, REACT_APP_PORTAL_URL } = process.env;

const useStyles = createUseStyles({
    filterSelector: {
        width: '100%',
        paddingBottom: '2em',
    },
    labelCenter: {
        textAlign: 'center',
    }
});

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const ExportAlps = () => {

    const classes = useStyles();
    const [cohortsForm, setCohorts] = useState([]);
    const [states, setStates] = useState();
    const [loadContent, setLoadContent] = useState(false);
    const BYCOHORT = 'byCohort';
    const BYSTATE = 'byState';
    const initialFormValues = { type_upload: BYCOHORT };

    const [form] = Form.useForm();
    const [typeUpload, setTypeUpload] = useState(BYCOHORT);

    const getCohorts = () => {
        API.get("api/cohort").then(({ data }) => setCohorts(data));
    };

    const getStates = () => {
        API.get("api/getStates").then(({ data }) => setStates(data.states));
    };


    const generateReport = () => {
        
        form.validateFields()
            .then((values) => {
                setLoadContent(true)

                    // Construir la URL con los parámetros de consulta
                    const queryString = Object.keys(values)
                    .map(key => {
                        if (Array.isArray(values[key])) {
                            // Si el valor es un array, construye múltiples parámetros de consulta con el mismo nombre
                            return values[key].map(value => key + '[]=' + encodeURIComponent(value)).join('&');
                        } else {
                            // Si no es un array, construye un solo parámetro de consulta
                            return key + '=' + encodeURIComponent(values[key]);
                        }
                    })
                    .join('&');const url = `api/export_alp_projects?${queryString}`;

                    // Realizar la solicitud GET
                    API.get(url, {
                        responseType: 'blob',
                        headers: { 'Content-Type': 'application/vnd.ms-excel' }
                    })
                    .then(response => {
                        const formatDate = moment(new Date()).format('YYYY-MM-DD hh_mm a');
                        const filename = `Alp Report Export ${formatDate}.xlsx`; // Nombre de archivo deseado
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        setLoadContent(false);
                    })
                    .catch(error => {
                        console.error('Error al descargar archivo:', error);
                    });
            })
            .catch((info) => {
                console.log("info", info);
            });
    }

    const setType = ([field]) => {
        if (field) {
            if (field.name[0] === "type_upload") {
                removeStateCohortDomain(field.value);

                if (field.value === BYSTATE) {
                    getStates();
                }

                setTypeUpload(field.value);
            }
        }
    }

    const removeStateCohortDomain = (type) => {
        const values = form.getFieldsValue()
        if (type === BYCOHORT) {
            form.setFieldsValue({ ...values, cohort: null })
        } else {
            form.setFieldsValue({ ...values, state: null})
        }
    }

    useEffect(() => {
        getCohorts();
        getStates();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <Row>
                <Col span={24} className={classes.filterSelector}>
                    <Form layout="vertical" form={form} 
                        initialValues={initialFormValues}
                        onFieldsChange={setType}
                    >

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="type_upload"
                            label="Please select which type of batch process you want to use. You may add Districts to accounts that have blank District data. If the account has a District associated with it, that will not be modified when using this batch process."
                        >
                            <Radio.Group>
                                <Radio style={radioStyle} value={BYCOHORT}>
                                    By Cohort
                                </Radio>
                                <Radio style={radioStyle} value={BYSTATE}>
                                    By State 
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                    <Row gutter={[0, 10]}>
                    <Col xs={24} md={6} lg={6}>
                        <Form.Item name="alp_type" placeholder='Project Type' label="Project Type">
                            <Select
                                placeholder="Project Type"
                                autoComplete="new-password"
                            >
                                 <Option key={34}>Action Learning Plan</Option>
                                 <Option key={3}>Contextual Analysis</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    </Row>

                    <Row gutter={[0, 10]}>
                    {typeUpload === BYCOHORT ?
                        <Col xs={24} md={6} lg={6}>
                            <Form.Item name="cohorts" rules={[{ required: true }]}  placeholder='Cohorts' label="Cohorts">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    placeholder="Cohorts"
                                    optionFilterProp="children"
                                    autoComplete="new-password"
                                    options={cohortsForm}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                ></Select>
                            </Form.Item>
                        </Col>
                        :
                            <Col xs={24} md={6} lg={6}>
                                    <Form.Item name="state" label="State" rules={[{ required: true }]}>
                                        <Select
                                            style={{
                                                width: "80%"
                                            }}
                                            showSearch
                                            options={states}
                                            placeholder="Select a State"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) === 0
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                       
                        }
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={classes.filterSelector}>
                            <Col span={3}>
                                <Button htmlType="submit" type="primary" onClick={() => { generateReport() }}>
                                    Download xlsx
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Spin spinning={loadContent}> </Spin>
        </>
    );
}

export default ExportAlps;
