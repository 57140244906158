import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as actionTypes from '../../../../store/actions/actionTypes';
import debounce from "lodash/debounce";
import {
    CaretDownOutlined, CaretUpOutlined, CloseOutlined, MailOutlined,
    PlusCircleOutlined,
    PlusOutlined, ExclamationCircleOutlined, CheckCircleOutlined,
} from "@ant-design/icons";
import {
    Button, Col, Divider, Layout, message, Modal, Row, Select, Space, Spin, Switch,
    Table, Typography
} from 'antd';
import { createUseStyles } from "react-jss";
import { useParams } from "react-router";
import { generatePath, useHistory, useRouteMatch, withRouter, useLocation } from "react-router-dom";
import API from "../../../../api";
import TernalCheckBox from "../../../../components/UI/TernalCheckBox";
import { alphabeticSort } from "../../../../functions/utilities/utilities";
import ReportsAndDownloads from "./ReportsAnddownloads/ReportsAndDownloads";
import CohortInfo from "../components/CohortInfo";
import ColaboratorsInfo from "../components/ColaboratorsInfo";
import SurveyResponsesPage from "./SurveyResponses/SurveyResponsesPage";
import { useReactToPrint } from "react-to-print";
import { loginWith } from "../../../../functions/loginWith";
import { EXECUTIVE_COACHING } from "../../../../config/courses";
import moment from "moment";
import { UserCheck } from "../../../../assets/icons/UserCheck";
import "./roster.less"
import _ from "lodash";
import { PROJECTS_BY_USERS } from "../../../../graphql/Roster/roster";
import { useApolloClient } from '@apollo/client';
import { AlpProgress } from "../../../../functions/AlpProgress";
import Dot from "./Dot";
import { CONTEXT_ANALYSIS_TEMPLATE, PORTFOLIO } from "../../../../config/typeSchema";

const { Content } = Layout;
const { Paragraph, Text } = Typography;
const { Option } = Select;

const useStyles = createUseStyles({
    verticalText: {
        '& span': {
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
        }
    },
    hiddeColumns: {
        display: "none",
    },
    sandStyle: {
        fontWeight: "bold",
    },
    successIcon: {
        color: "rgb(0, 137, 0) !important",
    },
    content: {
        padding: "10px 15px",
    },
    doubleLineButton: {
        height: "100%",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        lineHeight: "1em",
    },
    largeButton: {
        height: "2em",
        fontSize: "11px",
        background: "#fff",
        color: "#0051a0",
        width: "90%",
        lineHeight: "1em",
    },
    forceSelected: {
        color: '#fff !important',
        background: '#0051a0 !important',
        borderColor: '#0051a0 !important',
    },
    noForceSelected: {
        color: '#0051a0 !important',
        background: '#fff !important',
        borderColor: '#0051a0 !important',
    },
    blueText: {
        color: "#0051a0",
    },
    dateTime: {
        width: "11.7em",
        textAlign: "right",
    },
    fromTo: {
        width: "6rem",
        textAlign: "right",
    },
    blackText: {
        color: "black",

    },
    divider: {
        margin: '7px 0',
    },
    xSmallCellPadding: {
        '& th': {
            padding: '0px 6px !important',
        },
        '& td': {
            padding: '1px 6px !important',
        },
        '& td, th': {
            color: 'black !important',
            fontSize: "15px !important",
        },
    },
    progress: {
        height: '15px !important',
        display: 'flex',
        justifyContent: 'center',
        '& .ant-progress-steps-item': {
            width: '6px !important',
            height: '15px !important',
        },
    },
    addUserButton: {
        margin: '0 10px'
    },
    orderIcons: {
        fontSize: '10px',
    },
    deleteColor: {
        color: "red",
    },
    capitalize: {
        textTransform: "capitalize",
    },
    greenCheckbox: {
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: "#479320",
            borderColor: "#479320",
        }
    },
    unitSelector: {
        '& .ant-select-item-option': {
            padding: '0 !important',
        }
    },
    flex: {
        display: 'flex',
    },
    unitSelect: {
        width: '14.5em !important',
        '& .ant-select-selector': {
            height: '21px !important',
        },
        '& .ant-select-selection-item': {
            lineHeight: '19px !important',
        },
    },
    centerText : {
        textAlign : 'center !important'
    },
    dot: {
        height: '25px',
        width: '25px',
        verticalAlign: 'middle',
        backgroundColor: (props) => props?.dotColor || 'white',
        cursor: (props) => (props?.showModal && props?.showModal === true) ? 'pointer' : 'unset',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid #000',
        textAlign: 'center',
        marginLeft: (props) => props?.marginLeft || ''
    }
});

const Roster = ({ ...props }) => {
    const client = useApolloClient();
    const { pathname } = useLocation();
    let { path } = useRouteMatch();
    const classes = useStyles();
    const { cohortId, eventId, unitId, cohort } = useParams();
    const [reportsAndDownloadsModalVisibility, setReportsAndDownloadsModalVisibility] = useState(false);
    const [independentStudy, setIndependentStudy] = useState(false);
    const [toggleButton, setToggleButton] = useState(classes.doubleLineButton);
    const [dataSource, setDataSource] = useState([]);
    const [dataTableOne, setDataTableOne] = useState();
    const [dataTableTwo, setDataTableTwo] = useState();
    const [participants, setParticipants] = useState(false);
    const [platform, setPlatform] = useState();
    const [meetId, setMeetId] = useState();
    const [passwordMeet, setPasswordMeet] = useState();
    const [webinarLink, setWebinarLink] = useState();
    const [outState, setOutState] = useState(false);
    const [externalParticipant, setExternalParticipant] = useState();
    const [lastParticipantSearch, setLastParticipantSearch] = useState([]);
    const [cohortCity, setCohortCity] = useState([]);
    const [cohortCodeState, setCohortCodeState] = useState();
    const [cohortDeliveryInfo, setCohortDeliveryInfo] = useState({});
    const [cohortName, setCohortName] = useState([]);
    const [deliveryModel, setDeliveryModel] = useState([]);
    const [course, setCourse] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [courseStartDate, setCourseStartDate] = useState([]);
    const [facilitators, setFacilitators] = useState([]);
    const [cofacilitators, setCofacilitators] = useState([]);
    const [producers, setProducers] = useState([]);
    const [ALAdvise, setALAdvise] = useState([]);
    const [attendanceToSave, setAttendanceToSave] = useState();
    const [loadingTable, setLoadingTable] = useState(false);
    const [unitInfo, setUnitInfo] = useState();
    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();
    const [isExtended, setIsExtended] = useState(true);
    const [secondDay, setSecondDay] = useState(true);
    const [totalParticipants, setTotalParticipants] = useState();
    const [totalMakeups, setTotalMakeups] = useState();
    const [emails, setEmails] = useState([]);
    const [isStateCoordinator, setIsStateCoordinator] = useState(false);
    const [hasNislDrive, setHasNislDrive] = useState();
    const [linkFolder, setLinkFolder] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [isSeniorAdmin, setIsSeniorAdmin] = useState();
    const [attendanceChange, setAttendanceChange] = useState(false);
    const [selected, setSelected] = useState();
    const [sortDesc, setSortDesc] = useState(true);
    const [sortBy, setSortBy] = useState('lastName');
    const [totalWithProgress, setTotalWithProgress] = useState();
    const [changePath, setChangePath] = useState(false);
    const [meetingType, setMeetingType] = useState();
    const [participantsResponse, setParticipantsResponse] = useState(false);
    const [searchState, setSearchState] = useState(false);
    const [checkedOne, setCheckedOne] = useState(false);
    const [checkedTwo, setCheckedTwo] = useState(false);
    const [showUnitEvaluationLink, setShowUnitEvaluationLink] = useState(false);
    const [feedbackData, setFeedbackData] = useState();
    const [catData, setCatData] = useState();
    const [portfolioData, setPortfolioData] = useState();
    const [missingAttendance, setMissingAttendance] = useState([]);

    const { REACT_APP_PORTAL_NAME } = process.env;

    const { roles_un } = JSON.parse(localStorage.getItem("nisl-data"));
    (roles_un.includes("state_coordinator") && !isStateCoordinator) && setIsStateCoordinator(true);
    (roles_un.includes("administrator") && !isAdmin) && setIsAdmin(true);
    (roles_un.includes("senior_admin") && !isSeniorAdmin) && setIsSeniorAdmin(true);

    const [showResponsesModal, setShowResponsesModal] = useState(false);
    const [minDateExt, setMinDateExt] = useState();
    const [maxDateExt, setMaxDateExt] = useState();

    const [courseIdPostgres, setCourseIdPostgres] = useState();

    const [blockCheckbox, setBlockCheckbox] = useState(false);
    const [blockCheckboxTwo, setBlockCheckboxTwo] = useState(false);

    let history = useHistory();
    let columns = [
        {
            title: "Last Name",
            dataIndex: "lastName",
            width: "25%",
            filterIcon:
                <Space className={classes.orderIcons} direction='vertical' size={0}>
                    <CaretUpOutlined onClick={() => sortArrayObject('lastName', true)} />
                    <CaretDownOutlined onClick={() => sortArrayObject('lastName', false)} />
                </Space>,
            filterDropdownVisible: false,
            filtered: true,
            filters: [],
            render: (text, record) => {
                return (
                    <>
                        {isAdmin || isSeniorAdmin ?
                            <button span={16} style={{ width: '100%', textAlign: 'left', color: "#0051a0" }} className={`link-button`} onClick={() => {
                                localStorage.setItem('lastLoginPage', pathname)
                                loginWith(record.key)
                            }}  >{text}</button>
                        :  <Col span={16}>{text}</Col> }
                    </>
                )
            }
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            width: "25%",
            filterIcon:
                <Space className={classes.orderIcons} direction='vertical' size={0}>
                    <CaretUpOutlined onClick={() => sortArrayObject('firstName', true)} />
                    <CaretDownOutlined onClick={() => sortArrayObject('firstName', false)} />
                </Space>,
            filterDropdownVisible: false,
            filtered: true,
            filters: [],
            render: (text, record) => {
                return (
                    <Row justify="space-between">
                        {isAdmin || isSeniorAdmin ?
                            <button span={16} style={{ width: record?.isMakeup ? '83%' : '100%', textAlign: 'left', color: "#0051a0" }} className={`link-button`} onClick={() => {
                                localStorage.setItem('lastLoginPage', pathname)
                                loginWith(record.key)}} >{text}</button>
                        :  <Col span={16}>{text}</Col> }
                        {record?.isMakeup &&
                            <Col span={4} justify="rigth">
                                <CloseOutlined className={`${classes.deleteColor}`}
                                    onClick={() => removeParticipant(record)}
                                />
                            </Col>
                        }
                    </Row>
                )
            }
        },
        {
            title: <UserCheck/>,
            className : classes.centerText,
            dataIndex: 'attendance',
            width: "5%",
            render: (text, record) =>{
                let participant = text;
                let participantFormated = [];
                let unitsAbsent = [];
                let color = record.isMakeup || missingAttendance.includes(eventId) ? 'white' : 'green';
                let value = '';
                let showModal = false;

                if (text?.eventsAbsents?.length > 0) {
                    color = 'yellow'
                    text.eventsAbsents.map(eventAbsent => unitsAbsent.push(parseInt(eventAbsent)))
                    value = _.uniq(unitsAbsent).length;
                    showModal = true;
                }

                participant?.events && participant.events.map((event) => {
                    return participantFormated[event.eventId] = {
                        letter: event.letter,
                        color: event.color,
                        border: event.border,
                    }
                });

                let attendance = {
                    headers: record.headers,
                    attendance: participantFormated
                }

                return  <Dot dotColor={color} value={value} showModal={showModal} fromAttendance={true} attendance={attendance} firstName={participant?.firstName} lastName={participant?.lastName} ></Dot>
            }
        },
        {
            title: <><span>Self<br/>Relf.</span></>,
            className : classes.centerText,
            dataIndex: '',
            width: "5%",
            render: () =>{
                return  <Dot dotColor='white' value=''></Dot>
            }
        },
        {
            title: <><span >Feedback<br/>Diag.</span></>,
            className : classes.centerText,
            dataIndex: '',
            width: "5%",
            render: (text) =>{
                let color = '';
                if(feedbackData !== undefined && feedbackData.hasOwnProperty(text.nisl_id) !== undefined) {
                    if(feedbackData[text.nisl_id]?.DateCompleted === null && feedbackData[text.nisl_id]?.FormStatusID === 1){
                        color = 'yellow'
                    }else if(feedbackData[text.nisl_id]?.DateCompleted !== null && feedbackData[text.nisl_id]?.FormStatusID === 2) {
                        color = 'green';
                    }
                }
                return  <Dot dotColor={color} showModal={false}></Dot>
            }
        },
        {
            title: <><span>CAT<br/>Schema</span></>,
            className : classes.centerText,
            dataIndex: 'nisl_id',
            width: "5%",
            render: (text, record) =>{
                let showModal = false;
                let projects = catData?.filter(project => project.owner.mainAppUserId === text)?.sort((a,b)=>new Date(b.updated_at) - new Date(a.updated_at));
                let value = '';
                let title = 'Context Analysis Tool Schema';
                let colorDot = ''
                if (projects?.length > 0){
                    let {color} = AlpProgress(projects[0]?.progress)
                    colorDot = color;
                    value = projects.length;
                    showModal = true;
                }
                return <Dot showCoauthors={false} typeSchema={'schema'} projects={projects} dotColor={colorDot} value={value} fromAlp={true} title={title} showModal={showModal} firstName={record.firstName} lastName={record.lastName}></Dot>
            }
        },
        {
            title: <><span>Portfolio</span></>,
            className : classes.centerText,
            dataIndex: 'nisl_id',
            width: "5%",
            render: (text, record) =>{
                let showModal = false;
                let projects = portfolioData?.filter(project => project.owner.mainAppUserId === text)?.sort((a,b)=>new Date(b.updated_at) - new Date(a.updated_at));
                let value = '';
                let title = 'Action Learning Portfolio';
                let colorDot = ''
                if (projects?.length > 0){
                    let {color} = AlpProgress(projects[0]?.progress)
                    colorDot = color;
                    value = projects.length;
                    showModal = true;
                }
                return <Dot showCoauthors={true} typeSchema={'portfolio'} projects={projects} dotColor={colorDot} value={value} fromAlp={true} title={title} showModal={showModal} firstName={record.firstName} lastName={record.lastName}></Dot>
           }
        },
        {
            dataIndex: "eventOne",
            width: "5%",
            align: "right",
            render: (text, record) => {
                return (
                    <Space>
                        <a href={`mailto:${record.email}`} ><MailOutlined /></a>
                        {parseInt(courseId) !== EXECUTIVE_COACHING ?
                            <>
                                <TernalCheckBox
                                    defaultFirstChecked={record?.eventOne?.attendance}
                                    defaultSecondChecked={record?.eventOne?.independentAttendance}
                                    withSecondCheck={independentStudy}
                                    blockCheckbox={blockCheckbox}
                                    callBack={(checkBoxId, value) => modifyAttendance('eventOne', value, record.key)}
                                />
                                {record?.eventTwo &&
                                    <TernalCheckBox
                                        defaultFirstChecked={record?.eventTwo?.attendance}
                                        defaultSecondChecked={record?.eventTwo?.independentAttendance}
                                        withSecondCheck={independentStudy}
                                        blockCheckbox={blockCheckboxTwo}
                                        callBack={(checkBoxId, value) => modifyAttendance('eventTwo', value, record.key)}
                                    />
                                }
                            </>
                            : null}
                    </Space>
                )
            },
        },
    ];

    const handlerSelect = (value, option) => {
        const newPath = generatePath(path, { cohortId, eventId: option.value, unitId: option.unit_id, cohort });

        if (attendanceChange) {
            Modal.confirm({
                title: 'Unsaved changes, do you want to leave?',
                icon: <ExclamationCircleOutlined className={classes.bold} />,
                okText: "Yes",
                cancelText: "No",
                onOk() {
                    history.push(newPath);
                    setChangePath(!changePath);
                    setCheckedOne(false);
                    setCheckedTwo(false);
                },
            });
        } else {
            history.push(newPath);
            setChangePath(!changePath);
            setCheckedOne(false);
            setCheckedTwo(false);
        }
    }

    const reportsAndDownloadsModalHandler = () => {
        setReportsAndDownloadsModalVisibility(!reportsAndDownloadsModalVisibility);
    }
    const independentStudyHandler = () => {
        setIndependentStudy(!independentStudy);
        independentStudy
            ? setToggleButton(`${classes.doubleLineButton} ${classes.noForceSelected}`)
            : setToggleButton(`${classes.doubleLineButton} ${classes.forceSelected}`)
    }

    const sortArrayObject = (index, desc) => {
        setSortDesc(desc);
        setSortBy(index);
        setDataSource([...dataSource.sort((a, b) => alphabeticSort(a[index], b[index], desc))]);
    }

    const getParticipantsDb = (search) => {
        setLastParticipantSearch([]);
        setParticipants(false);
        if ((typeof search === 'undefined' || search.trim() === "") && sessionStorage.getItem('participantsDefault')) {
            let participantsDefault = JSON.parse(sessionStorage.getItem('participantsDefault'));
            setLastParticipantSearch(participantsDefault);
            setParticipants(participantsDefault.map((el, i) => {
                return {
                    key: i,
                    value: el.generalInformation.id,
                    label: `${el.generalInformation.firstName} ${el.generalInformation.lastName} ${el.generalInformation.email} `,
                }
            }))
            setParticipantsResponse(true);
        }
        else {
            setParticipantsResponse(false);
            setSearchState(search);
            API.post("api/extraParticipantList", {
                cohortId,
                outState,
                eventId,
                search,
            })
                .then(async (data) => {
                    setParticipants(await data.data.map((el, i) => {
                        return {
                            key: i,
                            value: el.generalInformation.id,
                            label: `${el.generalInformation.firstName} ${el.generalInformation.lastName} ${el.generalInformation.email} `,
                        }
                    }));
                    setLastParticipantSearch(data.data);
                    if (typeof search === 'undefined' || search.trim() === "") {
                        sessionStorage.setItem('participantsDefault', JSON.stringify(data.data));
                    }
                    setParticipantsResponse(true);
                })
                .catch((error) => {
                    console.log('Error: ', error);
                })
                .finally(() => {

                });
        }

    }
    const getParticipants = debounce(getParticipantsDb, 500);
    const addParticipant = () => {
        if (externalParticipant) {
            setLoadingTable(true);
            API.post("api/save_makeup_participant", {
                cohortId,
                eventUnit: eventId,
                participantId: externalParticipant.generalInformation.id,
            })
                .then(async (data) => {
                    if (data.data.isSaved) {
                        const formattedData = {
                            key: externalParticipant.generalInformation.id,
                            lastName: externalParticipant.generalInformation.lastName,
                            firstName: externalParticipant.generalInformation.firstName,
                            email: externalParticipant.generalInformation.email,
                            isMakeup: !externalParticipant.generalInformation.isMakeup,
                            all: 0,
                            new: 0,
                            eventOne: { attendance: false, independentAttendance: false },
                        };
                        if (!secondDay) { formattedData.eventTwo = { attendance: false, independentAttendance: false } };
                        setDataSource([...dataSource, formattedData].sort((a, b) => alphabeticSort(a[sortBy], b[sortBy], sortDesc)));
                        setEmails([...emails, externalParticipant.generalInformation.email]);
                        setLoadingTable(false);
                        setSelected(null);
                        setTotalMakeups(totalMakeups + 1);

                        setAttendanceToSave([
                            ...attendanceToSave,
                            {
                                key: attendanceToSave.length,
                                participantId: externalParticipant.generalInformation.id,
                                infoAttendance: externalParticipant.infoAttendance
                            }
                        ])
                        getRosterInfo()

                    } else {
                        message.error(data.data.message);
                    }
                    setLoadingTable(false);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });
        } else {
            message.error('Please select a participant');
        }
    }
    const removeParticipant = (record) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this makeup permanently?',
            icon: <ExclamationCircleOutlined className={classes.bold} />,
            onOk() {
                setLoadingTable(true);
                API.post("api/remove_makeup", {
                    cohortId,
                    participantId: record.key,
                    eventUnit: eventId,
                })
                    .then((data) => {
                        setLoadingTable(false);
                        setDataSource(dataSource.filter((el) => el.key !== record.key));
                        setEmails(emails.filter((email) => email !== record.email));
                        setTotalMakeups(totalMakeups - 1);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {

                    });
            },
            onCancel() {
                setLoadingTable(false);
            }
        });
    }
    const updateDataSource = (checked, event) => {
        event === 'eventOne' ? setCheckedOne(checked) : setCheckedTwo(checked);
        setAttendanceChange(true);
        const attendanceType = independentStudy ? 'independentAttendance' : 'attendance';
        setDataSource([...dataSource.map(e => {
            e[event][attendanceType] = checked;
            return e;
        }
        )]);
    }
    const getMettingInfo = () => {
        API.get(`api/get_meeting_info/${cohortId}`)
            .then(async (data) => {
                setPlatform(data.data.platform)
                setMeetId(data.data.meeting_id)
                setPasswordMeet(data.data.password_meeting)
                setWebinarLink(data.data.webinar_url)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const getCohortInfo = () => {
        API.get(`api/get_cohort_info/${cohortId}`)
            .then((data) => {
                props.doLastCohortInfo({
                    id: cohortId,
                    codeState: data.data.cohortInfo.codeState,
                    name: data.data.cohortInfo.name,
                    deliveryModel: data.data.cohortInfo.deliveryModel,
                    course: data.data.cohortInfo.course,
                    courseId: data.data.cohortInfo.courseCmsKey,
                    courseIdPostgres: data.data.cohortInfo.courseId,
                    courseStartDate: data.data.cohortInfo.firstEvent,
                    advisers: data.data.cohortInfo.advisers,
                    hasNislDrive: data.data.cohortInfo.hasNislDrive,
                    linkFolder: data.data.cohortInfo.linkFolder,
                    city: data.data.cohortInfo.city,
                    isPa: (!data.data.cohortInfo.isPa) ? false : true,
                    infoCohortDelivery: data.data.cohortInfo.deliveryModelData,
                });
                setCohortCodeState(data.data.cohortInfo.codeState);
                setCohortName(data.data.cohortInfo.name);
                setDeliveryModel(data.data.cohortInfo.deliveryModel);
                setCourse(data.data.cohortInfo.course);
                setCourseId(data.data.cohortInfo.courseCmsKey);
                setCourseIdPostgres(data.data.cohortInfo.courseId);
                setCourseStartDate(data.data.cohortInfo.firstEvent);
                setALAdvise(data.data.cohortInfo.advisers);
                setHasNislDrive(data.data.cohortInfo.hasNislDrive);
                setLinkFolder(data.data.cohortInfo.linkFolder);
                setCohortCity(data.data.cohortInfo.city);
                setCohortDeliveryInfo(data.data.cohortInfo.deliveryModelData);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const getUnitsCohort = () => {
        API.get(`api/get_events_list/${cohortId}`)
            .then(async (data) => {
                setUnitInfo(data.data.events.map((event) => {
                    return {
                        key: event.value,
                        label: event.value !== 'orientation' ? `Unit ${event.value}` : `Orientation`,
                        value: event.value,
                        unit_id: event.unit_id,
                        className: classes.blueText,
                    }
                }));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const getUnitInfo = () => {
        API.get(`api/unit/${cohortId}/${unitId}`)
            .then(({data}) => {
                let { allParticipants } =  data
                setEmails(allParticipants.flatMap(p => p.email));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const getRosterInfo = () => {
        setLoadingTable(true);
        API.get(`api/get_roster_info/${cohortId}/${eventId}`)
            .then((data) => {
                console.log(data);
                setFacilitators(data.data.facilitators.map(
                    (facilitators, i) => {
                        facilitators.key = i;
                        facilitators.lastName = facilitators.lastName === null ? '' : facilitators.lastName;
                        return facilitators;
                    })
                );
                setCofacilitators(data.data.cofacilitators.map(
                    (cofacilitator, i) => {
                        cofacilitator.key = i;
                        cofacilitator.lastName = cofacilitator.lastName === null ? '' : cofacilitator.lastName;
                        return cofacilitator;
                    })
                );
                setProducers(data.data.producers.map(
                    (producer, i) => {
                        producer.key = i;
                        producer.lastName = producer.lastName === null ? '' : producer.lastName;
                        return producer;
                    })
                );
                setIsExtended(data.data.isExtended);
                setDateStart(data.data.from);
                setDateEnd(data.data.to);
                setTotalParticipants(data.data.totalParticipants);
                setTotalMakeups(data.data.totalMakeups);
                setMeetingType(data.data.meetingType);

                //Disable the checkbox if the event is TBD or its ended date > today
                let now = moment().format("YYYY-MM-DD");
                if (data.data.isExtended === true) {
                    if (data.data.from === "TBD" || (data.data.from !== "TBD" && moment(data.data.from).format("YYYY-MM-DD") > now)) {
                        setBlockCheckbox(true);
                    } else {
                        setBlockCheckbox(false);
                    }
                }

                if (data.data.isExtended === false) {
                    if (data.data.from === "TBD" || (data.data.from !== "TBD" && moment(data.data.from).format("YYYY-MM-DD") > now)) {
                        setBlockCheckbox(true);
                    } else {
                        setBlockCheckbox(false);
                    }

                    if (data.data.to === "TBD" || (data.data.to !== "TBD" && moment(data.data.to).format("YYYY-MM-DD") > now)) {
                        setBlockCheckboxTwo(true);
                    } else {
                        setBlockCheckboxTwo(false);
                    }
                }

                let nislIds = [];
                const dataSourceFormat = data.data.participants.map((el) => {
                    const res = {
                        key: el.generalInformation.id,
                        nisl_id: el.generalInformation.nisl_id,
                        lastName: el.generalInformation.lastName,
                        firstName: el.generalInformation.firstName,
                        email: el.generalInformation.email,
                        isMakeup: el.generalInformation.isMakeup,
                        alpForm: el.generalInformation.alpForm,
                        new: 0,
                        eventOne: el.infoAttendance.filter((attendance) => attendance.day === 1)[0],
                        attendance: data.data.participantAttendance[el.generalInformation.id],
                        headers: data.data.headers
                    };
                    nislIds.push(el.generalInformation.nisl_id);
                    if (el.infoAttendance.filter((attendance) => attendance.day === 2)[0]) {
                        res.eventTwo = el.infoAttendance.filter((attendance) => attendance.day === 2)[0];
                        setSecondDay(false);
                    }
                    return res;
                });

                getCatData(nislIds)
                getPortfolioData(nislIds)
                getFeedbackData(nislIds.join(','));
                setDataSource(dataSourceFormat);
                setAttendanceToSave(data.data.participants.map((participant, i) => {
                    return {
                        key: i,
                        participantId: participant.generalInformation.id,
                        isMakeup: participant.generalInformation.isMakeup,
                        infoAttendance: participant.infoAttendance
                    }
                }));

                setMissingAttendance(data.data.missingAttendance)
                setLoadingTable(false);
                setTotalWithProgress(data.data.totalWithProgress);
                setMinDateExt(data.data.minDateExt);
                setMaxDateExt(data.data.maxDateExt);
                configureUnitEvaluationLink(data.data.unit, data.data.lastEvent);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const configureUnitEvaluationLink = (unit, lastEvent) => {
        const now = new Date();
        const dateOfEvent = new Date();
        if (lastEvent?.lastEventOfUnit?.end && unit !== 'orientation') {
            dateOfEvent.setFullYear(lastEvent.lastEventOfUnit.end.substring(0, 4));
            dateOfEvent.setMonth(parseInt(lastEvent.lastEventOfUnit.end.substring(5, 7)) - 1);
            dateOfEvent.setDate(lastEvent.lastEventOfUnit.end.substring(8, 10));
            dateOfEvent.setHours(0, 0, 1, 0);

            setShowUnitEvaluationLink(now >= dateOfEvent);
        } else {
            setShowUnitEvaluationLink(false);
        }
    };

    const saveAttendance = () => {
        if (blockCheckbox === true) {
            let message = `You cannot save attendance for future events. Please record attendance after the session has taken place.`;
            Modal.warning({
                icon: <ExclamationCircleOutlined />,
                content: message,
                okButtonProps: { type: "primary" },
                okText: "OK",
            });
        } else {
            setLoadingTable(true);
            API.post("api/save_attendance", {
                cohortId: cohortId,
                courseId: courseIdPostgres,
                unit: unitId,
                eventDay: eventId,
                isExtended : isExtended,
                participants: attendanceToSave
            })
                .then(() => {
                    Modal.info({
                        content: <div className={classes.flex}>Attendance has been saved. You may make any changes and re-save if required. </div>,
                        icon: <CheckCircleOutlined className={classes.successIcon} />,
                        okText: "OK",
                    });
                    getRosterInfo()
                    setLoadingTable(false);
                    setAttendanceChange(false);
                })
                .catch((error) => {
                    message.error('Attendance saved error: ' + error);
                })
                .finally(() => {
                    setLoadingTable(false);
                });
        }
    }

    const modifyAttendance = (event, value, recordId) => {
        setAttendanceChange(true);
        const attendanceType = independentStudy ? 'independentAttendance' : 'attendance';
        const dataUser = dataSource.findIndex(e => e.key === recordId);
        const data = [...dataSource];
        const participant = { ...data[dataUser] };
        if (
            participant.eventTwo
            && independentStudy
            && value
            && ((event === 'eventOne'
                && participant.eventTwo.independentAttendance)
                || (event === 'eventTwo'
                    && participant.eventOne.independentAttendance))
        ) {
            message.error(<>
                WARNING - Participants who miss an entire unit must make up that unit with another cohort.<br />
                The lead facilitator and/or the state coordinator can help to arrange a Make-up.<br />
                Independent Study can only be applied to one day of a unit.
            </>);
        } else {
            participant[event][attendanceType] = value;
            data[dataUser] = participant;
            setDataSource(data);
        }
    }

    const getFeedbackData = (nislIds) => {
        API.get(`api/get_feedback_diagnostic/${nislIds}`)
        .then(({data}) => {
            setFeedbackData(data.feedbackDiagnostic)
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const getCatData = (nislIds) => {
        client.query({
            query: PROJECTS_BY_USERS,
            variables: { galleryType: 'USERS', AlpType: [CONTEXT_ANALYSIS_TEMPLATE], users: nislIds },
        }).then(({ data: { projects } }) => setCatData(projects))
            .catch(error => console.log(error))

    }

    const getPortfolioData = (nislIds) => {
        client.query({
            query: PROJECTS_BY_USERS,
            variables: { galleryType: 'USERS', AlpType: [PORTFOLIO], users: nislIds },
        }).then(({ data: { projects } }) => setPortfolioData(projects))
            .catch(error => console.log(error))
    }

    const handleShowResponsesModal = () => {
        setShowResponsesModal(true);
    }

    useEffect(() => {
        const func = () => {

            props.doMenuOpen(true);
            (!props.lastCohortInfo || (props.lastCohortInfo.id !== cohortId)) ? getCohortInfo() : getCohortInfo();
            getMettingInfo();
            getRosterInfo();
            getUnitInfo()
            getUnitsCohort();
        };
        func();
    // eslint-disable-next-line
    }, [changePath]);

    useEffect(() => {
        if (dataSource) {
            setDataTableOne(dataSource.slice(0, Math.ceil(dataSource.length / 2)));
            setDataTableTwo(dataSource.slice(Math.ceil(dataSource.length / 2), dataSource.length));
        }
    }, [dataSource]);

    useEffect(() => {
        sessionStorage.removeItem('participantsDefault');
        setLastParticipantSearch([]);
        setParticipants(false);
    }, [outState]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => document.title = `Unit Evaluation Report - ${cohortName} - Unit ${unitId}`,
        onAfterPrint: () => document.title = REACT_APP_PORTAL_NAME
    });

    return (
        <Spin spinning={loadingTable}>
            <Content className={classes.content}>
                <Row>
                    <Col span={8}>
                        {platform &&
                            <Space>
                                <Col>
                                    <Button type="primary" className={classes.doubleLineButton}>
                                        <a href={webinarLink} target={'_blank'} rel="noopener noreferrer">
                                            <Space direction="vertical" size={1}>
                                                <div>Webinar Link</div>
                                                <div className={classes.capitalize}>{platform}</div>
                                            </Space>
                                        </a>
                                    </Button>
                                </Col>
                                <Col className={classes.blueText}>
                                    <Row>
                                        Meeting ID: {meetId}
                                    </Row>
                                    <Row>
                                        Password: {passwordMeet}
                                    </Row>
                                </Col>
                            </Space>
                        }
                    </Col>
                    <Col span={8} className={classes.title}>
                        <Row justify="center"><Text strong>ROSTER</Text></Row>
                    </Col>
                    <Col span={8}>
                        <Row justify="end">
                            <Space>
                                {hasNislDrive && <div >
                                    <Button type="primary" className={classes.doubleLineButton}>
                                        <a href={linkFolder} target={'_blank'} rel="noopener noreferrer">
                                            <Space direction="vertical" size={1}>
                                                <div>NISL</div>
                                                <div>Drive</div>
                                            </Space>
                                        </a>
                                    </Button>
                                </div>}
                                {isAdmin && <div className={classes.clearfocus}>
                                    <Button type="primary" onClick={independentStudyHandler} className={toggleButton}>
                                        <Space direction="vertical" size={1}>
                                            <div>Independent</div>
                                            <div>Study (I/S)</div>
                                        </Space>
                                    </Button>
                                </div>}
                                <Button onClick={reportsAndDownloadsModalHandler} type="primary" className={classes.doubleLineButton}>
                                    <Space direction="vertical" size={1}>
                                        <div>Reports and</div>
                                        <div>Downloads</div>
                                    </Space>
                                </Button>

                                <a href={`mailto:${emails}`}>
                                    <Button type="primary" className={classes.doubleLineButton}>
                                        <Space direction="vertical" size={1}>
                                            <div>Email</div>
                                            <div>All</div>
                                        </Space>
                                    </Button>
                                </a>
                                {parseInt(courseId) !== EXECUTIVE_COACHING ?
                                    <Button onClick={saveAttendance} type="primary" className={classes.doubleLineButton}>
                                        <Space direction="vertical" size={1}>
                                            <div>Save</div>
                                            <div>Attendance</div>
                                        </Space>
                                    </Button> : null}
                            </Space>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Divider className={classes.divider} />

                </Row>
                <Row gutter={16} className={classes.titleBar}>
                    <Col span={12}>
                        <Row>
                            <Col flex="1 1">
                                <CohortInfo
                                    cohortInfo={{
                                        course,
                                        cohortName,
                                        cohortCity,
                                        cohortCodeState,
                                        totalParticipants,
                                        totalMakeups,
                                        deliveryModel,
                                        cohortDeliveryInfo,
                                        courseId,
                                    }}
                                    loading={false}
                                    hasMakeups={true}
                                />
                            </Col>

                            {parseInt(courseId) !== EXECUTIVE_COACHING ?
                                <Col flex="0 1">
                                    <Row justify="end">
                                        <Select
                                            onSelect={(value, option) => handlerSelect(value, option)}
                                            defaultValue={eventId}
                                            options={unitInfo}
                                            className={classes.unitSelect} />
                                    </Row>
                                    <Row justify="end">
                                        <Col>
                                            <div className={classes.blackText}>{isExtended ? `Date: ` : `From: `}</div>

                                        </Col>
                                        <Col>
                                            <div className={isExtended ? `${classes.blueText} ${classes.dateTime}` : `${classes.blueText} ${classes.fromTo}`}>{dateStart || ``}</div>
                                        </Col>
                                    </Row>
                                    <Row justify="end" >
                                        <Col>
                                            <div className={classes.blackText}>{isExtended ? `Time: ` : `To: `}</div>
                                        </Col>
                                        <Col>
                                            <div className={isExtended ? `${classes.blueText} ${classes.dateTime}` : `${classes.blueText} ${classes.fromTo}`}>{dateEnd || ``}</div>
                                        </Col>
                                    </Row>
                                    <Row justify="end" >
                                        <div className={`${classes.blueText} ${classes.alignRigth}`}>{meetingType || ``}</div>
                                    </Row>
                                </Col>
                                : <Col>
                                    <Row justify="end">
                                        <Col>
                                            <div className={classes.blackText}>Start: </div>

                                        </Col>
                                        <Col>
                                            <div className={isExtended ? `${classes.blueText} ${classes.dateTime}` : `${classes.blueText} ${classes.fromTo}`}>{dateStart || ``}</div>
                                        </Col>
                                    </Row>
                                    <Row justify="end" >
                                        <Col>
                                            <div className={classes.blackText}>End: </div>
                                        </Col>
                                        <Col>
                                            <div className={isExtended ? `${classes.blueText} ${classes.dateTime}` : `${classes.blueText} ${classes.fromTo}`}>{dateEnd || ``}</div>
                                        </Col>
                                    </Row>
                                </Col>}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>

                            <Col span={17}>
                                <ColaboratorsInfo
                                    colaborators={{ facilitators, cofacilitators, producers, ALAdvise }}
                                    loading={false}
                                    showUnitEvaluationLink={showUnitEvaluationLink}
                                    showResponsesModal={handleShowResponsesModal}
                                    courseId={courseId}
                                />
                            </Col>
                            <Col span={7}>
                                <Row justify="end" align="middle">
                                {parseInt(courseId) !== EXECUTIVE_COACHING ?
                                    <Space direction="vertical" size={5}>
                                        <Row>Select All</Row>
                                        <Row>
                                            <Space size={5}>
                                                <Col>
                                                    <Space direction="vertical" size={1}>
                                                        Day 1
                                                        <Switch disabled={independentStudy || blockCheckbox ? true : false} onChange={(e) => updateDataSource(e, 'eventOne')} checked={checkedOne} />
                                                    </Space>
                                                </Col>
                                                <Col size={5}>
                                                    {!secondDay &&
                                                        <Space direction="vertical" size={1}>
                                                            Day 2
                                                            <Switch disabled={independentStudy || blockCheckboxTwo ? true : false} onChange={(e) => updateDataSource(e, 'eventTwo')} checked={checkedTwo} />
                                                        </Space>
                                                    }

                                                </Col>
                                            </Space>
                                        </Row>
                                    </Space>
                                : null}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row />
                <Row gutter={16} >
                    <Col span={12}>
                        {dataTableOne && <Table
                            className={classes.xSmallCellPadding}
                            size="small"
                            dataSource={dataTableOne}
                            columns={columns}
                            pagination={false} />}
                    </Col>
                    <Col span={12}>
                        {dataTableTwo && <Table
                            className={classes.xSmallCellPadding}
                            size="small"
                            dataSource={dataTableTwo}
                            columns={columns}
                            pagination={false} />}
                    </Col>
                </Row>
                <Row />
                <br />
                <Row>
                {parseInt(courseId) !== EXECUTIVE_COACHING ?
                    <Col span={12}>
                        <Row>
                            <Col span={17}>
                                <Select
                                    showSearch
                                    allowClear={true}
                                    filterOption={false}
                                    onFocus={() => getParticipants()}
                                    style={{ width: '80%' }}
                                    placeholder="Add a new participant"
                                    onChange={(e) => {
                                        setExternalParticipant(e);
                                        setSelected(e);
                                    }}
                                    onSearch={(val) => getParticipants(val)}
                                    onSelect={(val) => {
                                        setExternalParticipant(lastParticipantSearch.filter((el) => el.generalInformation.id === val)[0]);
                                        ;
                                    }}
                                    notFoundContent={
                                        participants.length === 0 && participantsResponse ? `The are no participants with “${searchState}”` : <Spin size="small" />
                                    }
                                    value={selected}
                                >
                                    {participants && participants.map((el) => <Option key={el.value} value={el.value}>{el.label}</Option>)}
                                </Select>
                                <Button
                                    onClick={addParticipant}
                                    className={classes.addUserButton}
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />
                                    } />
                            </Col>
                            <Col span={7}>
                                <Space>
                                    out-of-state
                                    <Switch onChange={(e) => setOutState(e)} />
                                </Space>

                            </Col>
                        </Row>
                        <Paragraph>
                            To add a guest to the roster, enter the guest's name,
                            select them, then click the <PlusCircleOutlined /> button
                            that appears next to their name.
                        </Paragraph>
                    </Col>
                : null}
                </Row>
                <Modal
                    width='65%'
                    footer={null}
                    key="newTab"
                    title='Roster Reports and Downloads'
                    visible={reportsAndDownloadsModalVisibility}
                    onCancel={reportsAndDownloadsModalHandler}
                >
                    {dataSource && <ReportsAndDownloads
                        dataSource={dataSource}
                        cohortInfo={{
                            course,
                            courseId,
                            courseStartDate,
                            cohortCodeState,
                            cohortCity,
                            cohortName,
                            deliveryModel,
                            totalParticipants,
                            totalMakeups,
                        }}

                        dataTable={dataSource.map((el) => {
                            return {
                                key: el.key,
                                lastName: el.lastName,
                                firstName: el.firstName,
                                isMakeup: el.isMakeup,
                            }
                        })}
                        sortDesc={sortDesc}
                        sortBy={sortBy}
                        unitId={unitId}
                        columns={columns.slice(0, 2)}
                        buttonClass={classes.largeButton}
                        eventInfo={{ secondDay, dateStart, dateEnd, unitId, eventId }}
                        cohortId={cohortId}
                        cohort={cohort}
                        totalWithProgress={totalWithProgress}
                        reportsAndDownloadsModalHandler={reportsAndDownloadsModalHandler}
                    />}

                </Modal>

                <Modal width='70%'
                    bodyStyle={{
                        maxHeight: window.innerHeight - 250 + "px",
                        overflowY: "scroll",
                        paddingTop: "0px",
                    }}
                    key="responsesModal"
                    title='Unit Evaluation Report'
                    visible={showResponsesModal}
                    onOk={() => setShowResponsesModal(false)}
                    onCancel={() => setShowResponsesModal(false)}
                    footer={
                        <Button type="primary" onClick={handlePrint}>Print</Button>
                    }
                    destroyOnClose={true}
                >
                    <SurveyResponsesPage
                        ref={componentRef}
                        cohortId={cohortId}
                        postId={cohort}
                        unit={unitId}
                        startUnit={dateStart}
                        endedUnit={dateEnd}
                        isExtended={isExtended}
                        minDateExt={minDateExt}
                        maxDateExt={maxDateExt}
                        cohortInfo={{
                            course,
                            cohortName,
                            cohortCity,
                            cohortCodeState,
                            totalParticipants,
                            totalMakeups,
                            deliveryModel,
                            facilitators
                        }}
                    />
                </Modal>
            </Content>
        </Spin>
    )
}
const mapStateToProps = state => ({
    menuOpen: state.menuOpen,
    lastCohortInfo: state.lastCohortInfo,
});

const mapDispatchToProps = dispatch => ({
    doMenuOpen: active => dispatch({ type: actionTypes.SET_MENU_OPEN, active: active }),
    doLastCohortInfo: active => dispatch({ type: actionTypes.SET_LAST_COHORT_INFO, active: active }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roster));
