import { setContext } from '@apollo/client/link/context';

export const authLink = setContext((_, { headers }) => {
    let { alp: { token } } = JSON.parse(localStorage.getItem('nisl-data'))
    return {
        headers: {
            ...headers,
            "x-nisl-alp-jwt": token ? token : ""
        }
    }
});

