
import React from "react";
import { Popover } from 'antd';
import AttendanceByUser from "./AttendanceByUser";
import AlpProjects from "./AlpProjects";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    dot: {
        height: '25px',
        width: '25px',
        verticalAlign: 'middle',
        backgroundColor: (props) => props?.dotColor || 'white',
        cursor: (props) => (props?.showModal && props?.showModal === true) ? 'pointer' : 'unset',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid #000',
        textAlign: 'center',
        marginLeft: (props) => props?.marginLeft || ''
    }
});

const Dot = (props) => {
    const classes = useStyles(props)
    let twoColumns = false;
    let content = <></>;
    let units = [];
    let title = '';
    let width = '30%';
    if(props.showModal && props.fromAttendance){
        let { headers } = props.attendance
        units = headers.filter(val => !isNaN(val.unitId))
        twoColumns = units.length > 6;
        content = <AttendanceByUser twoColumns={twoColumns} units={units} attendance={props.attendance}></AttendanceByUser>;
        title = 'Attendance';
    }

    if (props.showModal && props.fromAlp) {
        title = props.title;
        content = <AlpProjects showCoauthors={props.showCoauthors} typeSchema={props.typeSchema} projects={props.projects}></AlpProjects>
        width = props.showCoauthors ? '50%' : '35%';
    }

    return (
            props.showModal ?
                 <Popover overlayStyle={{ lineHeight: '2.5', width: width, textAlign: 'left' }} className={classes.dot} placement="rightTop" title={(<><span>{props.firstName} {props.lastName}:</span> <span style={{ fontWeight: 'bold' }}>{title}</span></>)} content={content} >
                     {props?.value}
                </Popover>
                :  <span className={classes.dot}>{props?.value}</span>

    )
}

export default Dot;
