import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionTypes from '../../store/actions/actionTypes';
import { Layout, Tabs } from "antd";
import UserPage from "./UserModules/Users/UserPage";
import RolePage from "./UserModules/Roles/RolePage";
import LoginAs from "./UserModules/LoginAs/LoginAs";
import ImportCsv from "./UserModules/Users/ImportCsv/ImportCsv";
import LogonPopup from "./LogonPopup/LogonPopup";
import ParticipantNotification from "./ParticipantNotification/ParticipantNotification";
import ChangeUsername from "./UserModules/changeUsername/ChangeUsername";
import PortalBanner from "./PortalBanner/Index";
import CohortBuilder from "./CohortBuilder/CohortBuilder";
import Bom from "./Bom/Bom";
import CoursePage from "./Courses/CoursePage";
import CertificatePage from './Certificates/CertificatePage';
import Pspdfkit from "./Pspdfkit/Pspdfkit";
import UserMerge from "./UserModules/UserMerge/UserMerge";
import UserMergeLogs from "./UserModules/UserMerge/UserMergeLogs";
import PendingUsers from "./UserModules/PendingUsers/PendingUsers";
import SystemSettings from "./SystemSettings/SystemSettings";
import ChangeDistrict from "./UserModules/ChangeDistrict/ChangeDistrict";
import ExportAlps from "./UserModules/ExportAlps/ExportAlps";


import "./admin.less"

const { Content } = Layout;

const { TabPane } = Tabs;

const Admin = ({ doMenuOpen }) => {


    const { roles: user_roles } = JSON.parse(localStorage.getItem("user-data"))
    const [isSuperAdmin, setSuperAdmin] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCoach, setIsCoach] = useState(false);


    useEffect(() => {
        if (user_roles?.find(v => v.slug === "senior_admin")) {
            setSuperAdmin(true);
        } else {
            setSuperAdmin(false);
        }

        if (user_roles?.find(v => v.slug === "administrator")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }

        if (user_roles?.find(v => v.slug === "coach")) {
            setIsCoach(true);
        } else {
            setIsCoach(false);
        }

        doMenuOpen(true);
    }, []);
    const [lastTabVisited, setLastTabVisited] = useState(
        localStorage.getItem("activeTabAdminModule") || "1"
    );
    const [activeTab, setActiveTab] = useState();

    useEffect(() => {
        localStorage.setItem("activeTabAdminModule", lastTabVisited);
    }, [lastTabVisited]);
    return (
        <Content className="container-fluid site-layout-background layout-portal-content">
            <Tabs
                style={{ marginTop: "20px" }}
                defaultActiveKey={lastTabVisited}
                tabPosition="top"
                animated="bottom"
                type="card"
                destroyInactiveTabPane={true}
                activeKey={activeTab}
                onChange={(activeKey) => {
                    setLastTabVisited(activeKey)
                    setActiveTab(activeKey)
                }}
            >
                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Users" key="1">
                        <UserPage />
                    </TabPane>
                    : ""
                }

                {(isSuperAdmin) ? <TabPane tab="Roles" key="2">
                    <RolePage />
                </TabPane> : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Login as" key="3">
                        <LoginAs />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Import from CSV" key="4">
                        <ImportCsv />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Add District" key="5">
                        <ChangeDistrict />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Logon Popup" key="6">
                        <LogonPopup />
                    </TabPane>
                    : ""
                }

                {(isSuperAdmin) ?
                    <TabPane tab="Participant Notification" key="7">
                        <ParticipantNotification />
                    </TabPane> : ""
                }

                {(isSuperAdmin) ?
                    <TabPane tab="Portal Banner" key="8">
                        <PortalBanner />
                    </TabPane> : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Change Username" key="9">
                        <ChangeUsername
                            setActiveKey={setActiveTab} />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Merge Accounts" key="10">
                        <UserMerge />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Merge Log" key="11">
                        <UserMergeLogs />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Pending Log" key="12">
                        <PendingUsers />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin || isCoach) ?
                    <TabPane tab="Cohort Builder" key="13">
                        <CohortBuilder />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="BOM" key="14">
                        <Bom />
                    </TabPane>
                    : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Certificates" key="15">
                        <CertificatePage />
                    </TabPane>
                    : ""
                }

                {(isSuperAdmin) ? <TabPane tab="Blanks" key="16">
                    <CoursePage />
                </TabPane> : ""
                }
                {(isSuperAdmin) ? <TabPane tab="PDF Log" key="17">
                    <Pspdfkit />
                </TabPane> : ""
                }
                {(isSuperAdmin) ? <TabPane tab="System Tools" key="18">
                    <SystemSettings />
                </TabPane> : ""
                }

                {(isAdmin || isSuperAdmin) ?
                    <TabPane tab="Export  ALPs" key="19">
                        <ExportAlps />
                    </TabPane>
                    : ""
                }

            </Tabs>
        </Content>
    );
};

const mapDispatchToProps = dispatch => ({
    doMenuOpen: active => dispatch({ type: actionTypes.SET_MENU_OPEN, active: active }),
});

export default connect(null, mapDispatchToProps)(Admin);
