import React from "react";
import { Col, Row } from 'antd';
import Dot from "./Dot";

const AttendanceByUser = (props) => {

    const GREEN_COLOR = '#008900';
    const WHITE_COLOR = '#FFFFFF'
    const RED_COLOR = '#FF0000';
    let renderData = (from, to) => {
        let { units } = props;
        return units.slice(from, to).map((header) => {
            let stringUnits = '';
            stringUnits = header.events.map(event => {
                let color = attendance[event.eventId].color;
                if (color !== RED_COLOR && color !== WHITE_COLOR) {
                    color = GREEN_COLOR;
                }
                return <Dot marginLeft='0.5em' dotColor={color} />
            })
            return <Row> <Col span={8} style={{ textAlign: 'center' }}>Unit {header.unitId}</Col> <Col span={16}>{stringUnits}</Col><br /></Row>
        })
    }
    let { attendance } = props.attendance
    return <>
        <Row gutter={[15, 15]}>
            <Col span={props.twoColumns ? 12 : 24}>{renderData(0, 6)}</Col>
            {props.twoColumns ? <Col span={12}>{renderData(6, 12)}</Col> : null}
        </Row>
        {
            <Row style={{ paddingTop: '10px', textAlign: 'center', lineHeight: '1.5' }}><div>A white circle means the session has not taken place or the facilitator has not yet recorded attendance. A green circle means the participant was marked in attendance. A red circle means the participant was marked not in attendance. (Once the participant attends a make-up session, the red circle will turn to green.)</div></Row>
        }
    </>

}

export default AttendanceByUser;
